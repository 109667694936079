<template>
  <div class="listing-details" v-if="house">
    <Head :type="0" share="true" title="房源详情" v-model="showShare" />
    
    
   
    <div class="main">
      <div class="swiper">
        <van-swipe @change="onChange" v-if="house.pics">
          <van-swipe-item v-for="item,index in house.pics" :key="index">
            <img :src="item" alt="房源图片">
          </van-swipe-item>
         
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{imgSize}}</div>
          </template>
        </van-swipe>
      </div>
      <div class="house-desc">{{house.houseName}}</div>
      <div class="house-mess">
        <div class="info-item" v-if="house.unitPrice">
          <div class="title">{{house.quotedPrice}}万</div>
          <div class="list">报价</div>
        </div>
        <div class="info-item" v-if="house">
          <div class="title" >{{house.bedroom+'室'+house.parlor+'厅'+house.bathroom+'卫'}}</div>
         
          <div class="list">户型</div>
        </div>
        <div class="info-item" v-if="house.area">
          <div class="title" style="line-height:.4rem">{{house.area}}m<sup>2</sup></div>
          <div class="list">建筑面积</div>
        </div>
        
      </div>
      <ul class="house-info">
        <li v-if="house.unitPrice&&house.area">
          <span class="key">单价 </span>
          <span class="value">{{house.unitPrice}} 元/m<sup>2</sup></span>
        </li>
        <li v-if="house.buildTime">
          <span class="key">年代 </span>
          <span class="value">{{house.buildTime}}年</span>
        </li>
        <li v-if="house.floorSum">
          <span class="key">楼层 </span>
          <span class="value">{{house.houseFloor}}(共{{house.floorSum}}层)</span>
        </li>
        <li v-if="house.areaType">
          <span class="key">类型 </span>
          <span class="value">{{house.areaType}}</span>
        </li>
        <li v-if="house.houseFase">
          <span class="key">朝向 </span>
          <span class="value">{{house.houseFase}}</span>
        </li>
        <li v-if="house.furnishType!=null">
          <span class="key">装修 </span>
          <span class="value" v-if="house.furnishType==='0'">精装修</span>
          <span class="value" v-if="house.furnishType==='1'">简装</span>
          <span class="value" v-if="house.furnishType==='2'">毛坯</span>
        </li>
         <li v-if="house.buyBudget">
          <span class="key">预算 </span>
          <span class="value">{{house.buyBudget}}</span>
        </li>
       
         <li v-if="house.communityName">
          <span class="key">小区</span>
          <span class="value">{{house.communityName}}</span>
        </li>
        <li v-if="house.rimInformation">
          <span class="key">周边</span>
          <span class="value">{{house.rimInformation}}</span>
        </li>
      </ul>
      <div class="advantage" v-if="house.pointSell">
        <div class="title">核心卖点</div>
        <div class=".content">{{house.pointSell}}</div>
      </div>
      <div class="advantage" v-if="house.ownerMentality">
        <div class="title">业主心态</div>
        <div class=".content">{{house.ownerMentality}}</div>
      </div>
      <div class="advantage" v-if="house.serviceIntroduce">
        <div class="title">服务介绍</div>
        <div class=".content">{{house.serviceIntroduce}}</div>
      </div>
    </div>
 

    <div class="footer" @touchmove.prevent>
      <div class="box" >
      <div class="collection">
      <img @click="shoucang()"  v-if="house.isCollect=='0'" src="../../assets/img/Star.png" alt="">
      <img  v-if="house.isCollect!='0'" src="../../assets/img/readstar.png"  @click="cCT(house.isCollect).then(()=>{house.isCollect='0'})"  alt="">
      <div>收藏</div>
      </div>
     
      <div class="btns" v-if="house">
        <div class="talk" @click="$contact(house.imAccount)">私聊顾问</div>
        <div class="get-phone">
          <a :href="'tel:'+house.sellPhone" style="color:white;">立即电联</a>
        </div>
      </div>
      </div>
    </div>
        <van-share-sheet v-model="showShare"  cancel-text="" title="立即分享给好友"  :options="options"  @select="onSelect"/>

  </div>
</template>
<script>
import {houseDetail} from '../../api/listingDetail/index'
import Head from "../../components/head/index.vue"
export default {
  data() {
    return {
      imgSize:0,
      current: 0,
        showShare: false,
         shareClass:{
              picUrl:null,
              platformId:null,
              title:null,
              openUrl:null,
              content:null
        },
      options: [
        [
          { name: '微信', icon: 'wechat',index:0 },
          { name: '朋友圈', icon: 'wechat-moments', index:1 },
          { name: 'QQ', icon: 'qq' ,index:2},
        ],
        
      ],
      house:null,
      data:''
    };
    
  },
  components:{Head},
  created(){
  try{ 
    this.$delete(this.$route.query, 'aaa');

}catch{ 
  //
}
 
     if(navigator.userAgent.indexOf('万民')==-1){
          this.openApp()
    return
  }
    let id = this.$route.query.id ||'1435443071066177538'
  this.$route.query.id=id
    this.$store.commit("setSession", this.$route.query.session||'1c45402e-4d67-4741-9689-bbf8636843b8' )

    houseDetail(id).then(res=>{
      console.log(res,54651);
      if(res.data.code===0){
        this.house=res.data.data
        this.imgSize= this.house.pics?this.house.pics.length:0
      }
    })
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
     openShare(){
    this.showShare=true
  },
  onSelect(index){
    // this.shareInfo(index.index)
    this.shareInfo({
      title:this.house.houseName,
      picUrl:this.house.pics?this.house.pics[0]:  "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
      platformId:index.index,
      openUrl:""  ,
      content:this.house.pointSell
    })
  },
    shoucang(){
      this.collection({
        type:'4',
        relationId:this.house.id
      }).then(res=>{
        if(res.data){
          this.$toast.success('收藏成功')
           houseDetail(this.$route.query.id).then(res=>{
          console.log(res);
          if(res.data.code===0){
         this.house=res.data.data
          this.imgSize=this.detail.imgUrls.length
      }
    })
        }else{
          this.$toast.fail('收藏失败')
        }
      })
    }
   
  
   
  //  shareInfo(val)
    
  //   {
  //     console.log(val)
  //     this.shareClass.picUrl=
  //      this.shareClass.platformId=val
  //      this.shareClass.title=  this.house.houseName  
  //      this.shareClass.openUrl="http://192.168.0.162:8080/#/helpCut?id=1431544528937959425"      
  //      this.shareClass.content=  this.house.pointSell
  //      let u = navigator.userAgent
  //      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
  //      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  //     if(isIOS)
  //     {
  //     window.webkit.messageHandlers.share.postMessage(JSON.stringify(this.shareClass));   
  //     }
  //     if (isAndroid) {
  //       if(this.$store.state.platformId!='1')
  //       {
  //        window.android.share(this.shareClass.platformId,
  //         this.shareClass.title,
  //         this.shareClass.openUrl,
  //         this.shareClass.content,
  //         this.share.picUrl
  //       ); 
  //       }
  //        else{
  //          window.android.shareImg(this.shareClass.platformId,
  //          this.checked.length>0?this.checked[0]:this.$store.state.sharePicUrl.length>0?this.$store.state.sharePicUrl[0]:'')
  //        }
  //     } 
  //    if(window.webkit){
  //       window.webkit.messageHandlers.share.postMessage(JSON.stringify(this.shareClass))
  //    }
  //   },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  
 
};
</script>

<style lang="less" scoped>
.listing-details::-webkit-scrollbar{
  display: none;
}
.listing-details {
  height:100%;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  
  .main::-webkit-scrollbar{
    display: none;
  }
  .main {
    
    flex: 1;
    box-sizing: border-box;
    padding-bottom: 2.666667rem;
    background-color: white;
    overflow: auto;
    .swiper {
      width: 100%;
      height: 4.8rem;
      
      margin-bottom: .453333rem;
      .custom-indicator {
        position: absolute;
        right: 0.366667rem;
        bottom: 0.166667rem;
        padding: 0.06667rem 0.26667rem;
        font-size: 0.16rem;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 30%;
      }
      .van-swipe {
        height: 105%;
        img{
          height: 100%;
          width: 100%;
        }
      }
    }
    .house-desc {
      height: 1.493333rem;
      font-size: 0.533333rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.746667rem;
      padding: 0 0.426667rem;
    }
    .house-mess {
      width: 100%;
      padding: 0 0.426667rem;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      padding-top: 0.266667rem;
      padding-bottom: 0.533333rem;
      justify-content: space-around;
    }
    .info-item {
        flex: 1;
      .title {
        height: 0.746667rem;
        font-size: 0.533333rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #a80000;
        line-height: 0.746667rem;
      }
      .list {
        height: 0.453333rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.453333rem;
      }
    }
    .house-info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      font-size: 0.32rem;
      justify-content: flex-start;
      margin-bottom: 1.066667rem;
      box-sizing: border-box;
      padding: 0 .426667rem;
      li {
        display: block;
        min-width: 50%;
        text-align: left;
        margin-bottom: 0.266667rem;
        .key {
          color: #ccc;
          margin-right: 0.133333rem;
        }
        .value {
          color: black;
        }
      }
    }
    .advantage{
      padding: 0  .426667rem;
      font-size: .373333rem;
      color: #aaa;
      white-space: pre-wrap;
      .title{
        color: black;
        font-weight: 600;
        margin-bottom: .266667rem;
      white-space: pre-wrap;

      }
    }
  }
  .footer{
    background-color: white;
    padding: 0 .426667rem 0 .666667rem;
     box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-sizing: border-box;
     padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    position: relative;
    .box{
    display: flex;
    padding:0.26rem 0;
      align-items: center;
    justify-content: center;

    }
    .collection{
      display: flex;
      flex-direction: column;
      margin-right: .666667rem;
      img{
        width: .666667rem;
        height: .64rem;
      }
      div{
        font-size: .32rem;
        transform: scale(0.83);
      }
    }
    .btns{
      display: flex;
      flex: 1;
      color: white;
      font-size: .186667rem;
      text-align: center;
      align-items: center;
      height: .96rem;
      font-size: .373333rem;
      .talk{
        width: 3.773333rem;
        background-image: url('../../assets/img/btn1_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .get-phone{
         width: 3.773333rem;
        background-image: url('../../assets/img/btn2_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


      }
    }

  }
}
</style>